var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('SpinnerLogo') : _vm._e(), _c('p', [_vm._v("Websocket Connected: " + _vm._s(_vm.isConnected))]), _c('div', [_c('button', {
    staticClass: "btn btn-info mb-2",
    on: {
      "click": _vm.onClickPingSocket
    }
  }, [_vm._v("Ping")]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Ping last response: " + _vm._s(_vm.ping.lastMessage.data))]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Ping response time: " + _vm._s(_vm.ping.lastMessage.timestamp))])]), _vm.isConnected ? _c('div', [_c('button', {
    staticClass: "btn btn-warning mb-2",
    on: {
      "click": _vm.onClickDisconnectSocket
    }
  }, [_vm._v("Disconnect")])]) : _vm._e(), !_vm.isConnected ? _c('div', [_c('button', {
    staticClass: "btn btn-primary mb-2",
    on: {
      "click": _vm.onClickConnectSocket
    }
  }, [_vm._v("Connect")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }