<template>
  <div>
    <SpinnerLogo v-if="loading" />
    <p>Websocket Connected: {{ isConnected }}</p>
    <div>
      <button class="btn btn-info mb-2" @click="onClickPingSocket">Ping</button>
      <div class="font-w600">Ping last response: {{ ping.lastMessage.data }}</div>
      <div class="font-w600">Ping response time: {{ ping.lastMessage.timestamp }}</div>
    </div>
    <div v-if="isConnected"><button class="btn btn-warning mb-2" @click="onClickDisconnectSocket">Disconnect</button></div>
    <div v-if="!isConnected"><button class="btn btn-primary mb-2" @click="onClickConnectSocket">Connect</button></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SpinnerLogo from '@/components/SpinnerLogo';

export default {
  name: 'AdminToolsWebsocket',
  components: {
    SpinnerLogo
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isConnected: 'socket/isConnected',
      ping: 'socket/ping'
    })
  },
  async mounted() {
    this.connect();
  },
  async beforeDestroy() {
    this.disconnect();
  },
  methods: {
    ...mapActions({
      connect: 'socket/connect',
      disconnect: 'socket/disconnect',
      emit: 'socket/emit'
    }),
    onClickPingSocket() {
      this.emit({ action: 'ping' });
    },
    onClickConnectSocket() {
      this.connect();
    },
    onClickDisconnectSocket() {
      this.disconnect();
    }
  }
};
</script>
